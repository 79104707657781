import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { LiaUserSlashSolid } from "react-icons/lia";
import { FaCircleUser } from "react-icons/fa6";
import Loading from "../../assets/rff.svg";
import Config from "../../config/Links";
import { Link, useParams } from "react-router-dom";
import { CiFilter } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import "moment/locale/fr";
import { LuUsers2 } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import C_loading from "../../assets/c_loading.svg";
import { IoMdCopy } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";

const gesUrl = Config.GESCAPO_LINK;
const url = Config.G_PERFORM_LINK;
const gesToken = sessionStorage.getItem("gestoken");
const token = sessionStorage.getItem("admin_tk");

const menu = [
  { id: 0, title: `Mois de ${moment().subtract(1, "month").format("MMMM")}` },
  { id: 1, title: `Mois en cours` },
];

function AgenceDetails() {
  const { id } = useParams();
  moment.locale("fr");
  const [select, setSelect] = useState(1);
  const [colName, setColName] = useState("");
  const [carnetsData, setCarnetsData] = useState(0);
  const [colFilter, setColFilter] = useState([]);
  const [agenceData, setAgenceData] = useState([]);
  const [salaireColecteurEnCours, setSalaireColecteurEnCours] = useState([]);
  const [compInfo, setCompInfo] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalCommissionP, setTotalCommissionP] = useState(0);
  const [totalCommissionE, setTotalCommissionE] = useState(0);
  const [totalCommissionC, setTotalCommissionC] = useState(0);
  const [calculatedCommission, setCalculatedCommission] = useState(0);

  // Modals
  const [productInfoModal, setProductInfoModal] = useState(false);
  const [epargneInfoModal, setEpargneInfoModal] = useState(false);
  const [carnetInfoModal, setCarnetInfoModal] = useState(false);
  const [totalInfoModal, setTotalInfoModal] = useState(false);

  //  Loading
  const [loadingP, setLoadingP] = useState(false);
  const [loadingE, setLoadingE] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingT, setLoadingT] = useState(false);

  // Fetch Coll
  useEffect(() => {
    const fetchCollectors = async () => {
      try {
        const response = await axios.post(
          `${gesUrl}/api/auth/admin/agent_collecteur/list?all=true&search=${search}`,
          { search },
          {
            headers: { Authorization: `Bearer ${gesToken}` },
          }
        );
        if (response.status === 200) {
          const filteredCollectors = response.data.data.filter(
            (collecteur) => collecteur.id_agence == id
          );
          setColFilter(filteredCollectors);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des collecteurs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCollectors();
  }, [id, search]);

  // Fetch All Agence
  useEffect(() => {
    const fetchAgence = async () => {
      try {
        const response = await axios.post(
          `${gesUrl}/api/auth/admin/agence/list?all=true`,
          {},
          {
            headers: { Authorization: `Bearer ${gesToken}` },
          }
        );
        setAgenceData(response.data.data.filter((agence) => agence.id === id));
      } catch (error) {
        console.error("Erreur lors de la récupération des agences:", error);
      }
    };
    fetchAgence();
  }, [id]);

  // Fetch Salaire en cours
  const fetchSalaireMout = useCallback(
    async (id) => {
      try {
        const response = await axios.post(
          `${gesUrl}/api/auth/admin/transaction/collecteur/${id}`,
          {},
          {
            headers: { Authorization: `Bearer ${gesToken}` },
          }
        );
        setSalaireColecteurEnCours(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération du salaire:", error);
      }
    },
    [gesUrl, gesToken]
  );

  useEffect(() => {
    fetchSalaireMout(id);
  }, [id, fetchSalaireMout]);

  // Fetch Company Info
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(`${url}/v1/get/company/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompInfo(response.data.companyInfo);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCompany();
  }, []);

  // Date de début et de fin du mois en cours
  const startDate = moment().startOf("month").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  // Date de début et de fin du mois précédent
  const previewStartDate = moment()
    .subtract(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");

  const previewEndDate = moment()
    .subtract(1, "month")
    .endOf("month")
    .format("YYYY-MM-DD");

  // Carnet Commission
  const carnetCom = 100;

  // Mois en cours Carnet
  const fetchCarnets = useCallback(
    async (id) => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${gesUrl}/api/auth/admin/agent_collecteur/statistique/by-period/${id}?endDate=${endDate}&startDate=${startDate}`,
          headers: {
            Authorization: `Bearer ${gesToken}`,
          },
        };

        const response = await axios.request(config);
        console.log("Carnets data (current month):", response.data.data);

        if (
          response.data &&
          response.data.data &&
          typeof response.data.data.totalCarnets === "number"
        ) {
          setCarnetsData(response.data.data);
          setTotalCommissionC(response.data.data.totalCarnets * carnetCom);
        } else {
          console.error(
            "Invalid data structure or totalCarnets is not a number"
          );
          setTotalCommissionC(0);
        }
      } catch (error) {
        console.error("Error fetching current month carnets:", error);
        setTotalCommissionC(0);
      }
    },
    [gesUrl, gesToken, endDate, startDate, carnetCom]
  );

  // Mois précédent Carnet
  const fetchPreviewMonthCarnets = useCallback(
    async (id) => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${gesUrl}/api/auth/admin/agent_collecteur/statistique/by-period/${id}?endDate=${previewEndDate}&startDate=${previewStartDate}`,
          headers: {
            Authorization: `Bearer ${gesToken}`,
          },
        };

        const response = await axios.request(config);
        console.log("Carnets data (previous month):", response.data.data);

        if (
          response.data &&
          response.data.data &&
          typeof response.data.data.totalCarnets === "number"
        ) {
          setCarnetsData(response.data.data);
          setTotalCommissionC(response.data.data.totalCarnets * carnetCom);
        } else {
          console.error(
            "Invalid data structure or totalCarnets is not a number"
          );
          setTotalCommissionC(0);
        }
      } catch (error) {
        console.error("Error fetching previous month carnets:", error);
        setTotalCommissionC(0);
      }
    },
    [gesUrl, gesToken, previewEndDate, previewStartDate, carnetCom]
  );

  // Recalculer les commissions des produits lorsque les données ou les filtres changent
  useEffect(() => {
    const calculateCommissionP = async () => {
      const currentMonth4th = moment().subtract(1, "month").date(31);
      const previousMonth4th = moment().subtract(1, "month").date(1);
      setLoadingP(true);
      try {
        let commissionTotal = 0;
        if (salaireColecteurEnCours && salaireColecteurEnCours.length > 0) {
          salaireColecteurEnCours.forEach((item) => {
            const itemDate = moment(item.created_at);
            let itemMontant = parseFloat(item.montant);
            let itemCommission = 0;

            if (item.type === 0) {
              itemCommission =
                (parseFloat(compInfo.payment_pourcent) / 100) * itemMontant;
              if (select === 1) {
                if (
                  itemDate.year() === moment().year() &&
                  itemDate.month() === moment().month()
                ) {
                  commissionTotal += itemCommission;
                }
              } else if (select === 0) {
                if (
                  itemDate.isBetween(
                    previousMonth4th,
                    currentMonth4th,
                    undefined,
                    "[]"
                  )
                ) {
                  commissionTotal += itemCommission;
                }
              }
            }
          });
        }
        setTotalCommissionP(commissionTotal);
      } finally {
        setLoadingP(false);
      }
    };
    calculateCommissionP();
  }, [salaireColecteurEnCours, compInfo, select]);

  // Recalculer les commissions d'épargne lorsque les données ou les filtres changent
  useEffect(() => {
    const calculateCommissionE = async () => {
      const currentMonth4th = moment().subtract(1, "month").date(31);
      const previousMonth4th = moment().subtract(1, "month").date(1);
      setLoadingE(true);
      try {
        let commissionTotal = 0;

        if (salaireColecteurEnCours && salaireColecteurEnCours.length > 0) {
          salaireColecteurEnCours.forEach((item) => {
            if (item.type === 1) {
              const itemDate = moment(item.created_at);
              let itemMontant = parseFloat(item.montant);
              let itemCommission = 0;

              if (select === 1) {
                itemCommission =
                  (parseFloat(compInfo.epargne_pourcent) / 100) * itemMontant;

                if (
                  itemDate.year() === moment().year() &&
                  itemDate.month() === moment().month()
                ) {
                  commissionTotal += itemCommission;
                }
              } else if (
                select === 0 &&
                itemDate.isBetween(
                  previousMonth4th,
                  currentMonth4th,
                  undefined,
                  "[]"
                )
              ) {
                itemCommission =
                  (parseFloat(compInfo.epargne_pourcent) / 100) * itemMontant;
                commissionTotal += itemCommission;
              }
            }
          });
        }
        setTotalCommissionE(commissionTotal);
      } finally {
        setLoadingE(false);
      }
    };
    calculateCommissionE();
  }, [salaireColecteurEnCours, compInfo, select]);

  // Recalculer les commissions des carnets lorsque les données ou les filtres changent
  useEffect(() => {
    const calculateCommissionC = async () => {
      setLoadingC(true);
      try {
        if (carnetsData) {
          setTotalCommissionC(
            parseFloat(carnetsData.totalCarnets * carnetCom) || 0
          );
        }
      } finally {
        setLoadingC(false);
      }
    };
    calculateCommissionC();
  }, [carnetsData]);

  //previewHandleDetailClick
  const previewHandleDetailClick = async (nom, id) => {
    setLoadingT(true);
    setTotalInfoModal(true);
    setColName(nom);

    try {
      // Appels de fetch en parallèle
      await Promise.all([fetchPreviewMonthCarnets(id), fetchSalaireMout(id)]);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoadingT(false);
    }
  };

  // New Month Detail Click
  const handleTotalDetailClick = async (nom, id) => {
    setTotalInfoModal(true);
    setColName(nom);
    setLoadingT(true);

    try {
      await Promise.all([fetchCarnets(id), fetchSalaireMout(id)]);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoadingT(false);
    }
  };

  // Calculer la commission totale
  useEffect(() => {
    const calculateTotalCommission = () => {
      // Démarrer le chargement
      setLoadingT(true);

      // Calculer la commission
      const total = totalCommissionP + totalCommissionE + totalCommissionC;

      setCalculatedCommission(total);

      if (calculatedCommission) {
        setTimeout(() => {
          setLoadingT(false);
        }, 2500);
      }
    };

    calculateTotalCommission();
  }, [totalCommissionP + totalCommissionE + totalCommissionC]);

  return (
    <>
      <div className="h-[95vh] pt-[54px] overflow-auto scrollbar-hide flex flex-col items-center justify-center">
        {/* Col Search */}
        <div className="w-11/12 mx-3 mt-12 h-[50px] my-2 flex flex-row justify-between ">
          <div className="bg-[#ADADAD36] rounded-md p-1 w-[300px] flex flex-row items-center">
            <CiFilter color="white" size={25} />
            <input
              className="border-none outline-none bg-transparent p-2 text-white"
              placeholder="Filtrer les Collecteurs"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {/* title */}
          {select === 0 ? (
            <div className="flex flex-row items-center p-2">
              <LuUsers2 color="white" size={28} />
              {select === 0 && (
                <p className="text-white font-bold text-xl ml-2">
                  Salaire Mois {moment().subtract(1, "month").format("MMMM")}
                </p>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center p-2">
              <LuUsers2 color="white" size={28} />

              {select === 1 && (
                <p className="text-white font-bold text-xl ml-2">
                  Salaire du mois en cours.
                </p>
              )}
            </div>
          )}

          {/* Menu */}
          <div className="w-3/12 bg-[#ADADAD36] rounded-xl h-[60px]">
            {menu.map((item) => (
              <button
                onClick={() => setSelect(item.id)}
                key={item.id}
                className={
                  select === item.id
                    ? `w-[45%] shadow-md h-[48px] mb-1 border-none text-white bg-blue-500 rounded-md mx-2 my-2 transition duration-300 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-500`
                    : `w-[45%] shadow-md h-[48px] mb-1 border-none text-white bg-[#0f172a34] rounded-md mx-2 my-2 transition duration-300 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-500`
                }
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>

        {/* Liste de collecteurs */}
        {select === 0 ? (
          <div className="w-full flex p-2 max-sm:p-0 flex-wrap max-sm:flex-col items-center justify-center">
            <div className="h-[80vh] mx-3 w-10/12 max-sm:mx-0 mb-3 rounded-xl max-sm:w-full overflow-auto scrollbar-hide flex flex-col items-center">
              <div className="overflow-auto scrollbar-hide w-full">
                {loading ? (
                  <div className="flex justify-center h-full items-center">
                    <img className="w-12 h-12" src={Loading} alt="Loading" />
                  </div>
                ) : colFilter.length > 0 ? (
                  colFilter.map(({ id, nom, numero, prenom, agc_name }) => (
                    <Link
                      // to={`/dashboard/payment/pending/produit/col/${id}`}
                      key={id}
                      className="flex flex-col justify-between px-2 py-2 my-2 bg-[#001d3f]  rounded-md w-full"
                    >
                      <div>
                        {/* Block 1 */}
                        <div className="flex flex-row w-full justify-between"></div>

                        {/* Block 2 */}
                        <div className="w-full flex flex-row p-2 rounded-md justify-between">
                          {/* Collecteur Info */}
                          <div className="flex flex-row">
                            <div>
                              <FaCircleUser color="white" size={50} />
                            </div>

                            <div className="ml-5">
                              <p className="text-white">
                                {nom}{" "}
                                {prenom.length > 6 &&
                                  `${prenom.slice(0, 5)}...`}
                              </p>
                              <p className="text-white">{numero}</p>
                              <p className="text-green-400">{agc_name}</p>
                            </div>
                          </div>

                          {/* Produit */}
                          <div>
                            <p className="font-medium text-white">
                              T. Commission produit
                            </p>

                            {/* Detail */}
                            <button
                              onClick={() => {
                                setProductInfoModal(true);
                                fetchSalaireMout(id);
                                setColName(nom);
                                setLoadingP(true);
                              }}
                              className="bg-emerald-300 rounded-md p-2 hover:bg-slate-700"
                            >
                              <p>Détail</p>
                            </button>
                          </div>

                          {/* Epargne */}
                          <div>
                            <p className="font-medium text-white">
                              T.Commission Epargne
                            </p>

                            {/* Detail */}
                            <button
                              onClick={() => {
                                setEpargneInfoModal(true);
                                fetchSalaireMout(id);
                                setColName(nom);
                                setLoadingE(true);
                              }}
                              className="bg-emerald-300 rounded-md p-2 hover:bg-slate-700"
                            >
                              <p>Détail</p>
                            </button>
                          </div>

                          {/* Carnet */}
                          <div>
                            <p className="font-medium text-white">
                              T. Commission Carnet
                            </p>

                            {/* Detail */}
                            <button
                              onClick={() => {
                                setCarnetInfoModal(true);
                                fetchPreviewMonthCarnets(id);
                                setColName(nom);
                                setLoadingC(true);
                              }}
                              className="bg-emerald-300 rounded-md p-2 hover:bg-slate-700"
                            >
                              <p>Détail</p>
                            </button>
                          </div>

                          {/* Total payment */}
                          <div>
                            <p className="font-medium text-white">Total</p>

                            {/* Detail */}
                            <button
                              onClick={() => {
                                previewHandleDetailClick(nom, id);
                              }}
                              className="bg-emerald-300 rounded-md p-2 hover:bg-slate-700"
                            >
                              <p>Détail</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <LiaUserSlashSolid size={35} color="white" />
                    <p className="text-white">Aucun collecteur trouvé !</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          select === 1 && (
            <div className="w-full flex p-2 max-sm:p-0 flex-wrap max-sm:flex-col items-center justify-center">
              <div className="h-[80vh] mx-3 w-10/12 max-sm:mx-0 mb-3 rounded-xl max-sm:w-full overflow-auto scrollbar-hide flex flex-col items-center">
                <div className="overflow-auto scrollbar-hide w-full">
                  {loading ? (
                    <div className="flex justify-center h-full items-center">
                      <img className="w-12 h-12" src={Loading} alt="Loading" />
                    </div>
                  ) : colFilter.length > 0 ? (
                    colFilter.map(({ id, nom, numero, prenom, agc_name }) => (
                      <Link
                        // to={`/dashboard/payment/pending/produit/col/${id}`}
                        key={id}
                        className="flex flex-col justify-between px-2 py-2 my-2 bg-slate-900 rounded-md w-full"
                      >
                        <div>
                          {/* Block 1 */}
                          <div className="flex flex-row w-full justify-between"></div>

                          {/* Block 2 */}
                          <div className="w-full flex flex-row p-2 rounded-md justify-between">
                            {/* Collecteur Info */}
                            <div className="flex flex-row">
                              <div>
                                <FaCircleUser color="white" size={50} />
                              </div>

                              <div className="ml-5">
                                <p className="text-white">
                                  {nom}{" "}
                                  {prenom.length > 6 &&
                                    `${prenom.slice(0, 5)}...`}
                                </p>
                                <p className="text-white">{numero}</p>
                                <p className="text-green-400">{agc_name}</p>
                              </div>
                            </div>

                            {/* Produit */}
                            <div>
                              <p className="font-medium text-white">
                                T. Commission produit
                              </p>

                              {/* Detail */}
                              <button
                                onClick={() => {
                                  setProductInfoModal(true);
                                  setLoadingP(true);
                                  fetchSalaireMout(id);
                                  setColName(nom);
                                }}
                                className="bg-slate-500 rounded-md p-2 hover:bg-slate-700"
                              >
                                <p>Détail</p>
                              </button>
                            </div>

                            {/* Epargne */}
                            <div>
                              <p className="font-medium text-white">
                                T.Commission Epargne
                              </p>

                              {/* Detail */}
                              <button
                                onClick={() => {
                                  setEpargneInfoModal(true);
                                  fetchSalaireMout(id);
                                  setColName(nom);
                                  setLoadingE(true);
                                }}
                                className="bg-slate-500 rounded-md p-2 hover:bg-slate-700"
                              >
                                <p>Détail</p>
                              </button>
                            </div>

                            {/* Carnet */}
                            <div>
                              <p className="font-medium text-white">
                                T. Commission Carnet
                              </p>

                              {/* Detail */}
                              <button
                                onClick={() => {
                                  setCarnetInfoModal(true);
                                  fetchCarnets(id);
                                  setColName(nom);
                                  setLoadingC(true);
                                }}
                                className="bg-slate-500 rounded-md p-2 hover:bg-slate-700"
                              >
                                <p>Détail</p>
                              </button>
                            </div>

                            {/* Total payment */}
                            <div>
                              <p className="font-medium text-white">Total</p>

                              {/* Detail */}
                              <button
                                onClick={() => {
                                  handleTotalDetailClick(nom, id);
                                }}
                                className="bg-slate-500 rounded-md p-2 hover:bg-slate-700"
                              >
                                <p>Détail</p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <LiaUserSlashSolid size={35} color="white" />
                      <p className="text-white">Aucun collecteur trouvé !</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {/* Product info Modal */}
      <div
        onClickCapture={() => setProductInfoModal(false)}
        className={
          productInfoModal
            ? "flex absolute top-0 left-0 right-0 justify-center items-center rounded-md bg-[#00000024] w-full h-screen"
            : "hidden"
        }
      >
        <div
          className={
            productInfoModal
              ? " w-3/12 h-[220px] flex flex-col  rounded-md  bg-sky-900"
              : "hidden"
          }
        >
          {/* Header */}
          <div className="w-full flex flex-row justify-between p-2">
            <button className="bg-gray-200 w-8 h-8 flex opacity-0 rounded-full justify-center items-center ">
              <IoMdClose size={20} color="black" />
            </button>

            <div className="flex flex-col">
              <p className="font-bold text-lg text-white">Commission Produit</p>
              <p className="font-bold text-center text-white">({colName})</p>
            </div>

            <button
              onClick={() => setProductInfoModal(false)}
              className="bg-gray-200 w-8 h-8 flex hover:bg-gray-500 rounded-full justify-center items-center "
            >
              <IoMdClose size={20} color="black" />
            </button>
          </div>

          {/* Body */}
          {/* Commission du mois */}
          <span className="flex flex-col items-center">
            <p className="font-bold text-white">Commission de ce mois</p>
            <p className="text-green-400 font-bold text-lg">
              {loadingP ? (
                <img src={C_loading} className="w-5 h-5" />
              ) : (
                (totalCommissionP || 0).toLocaleString("fr-FR")
              )}{" "}
              FCFA
            </p>
          </span>
        </div>
      </div>

      {/* Epargne info Modal */}
      <div
        onClickCapture={() => setEpargneInfoModal(false)}
        className={
          epargneInfoModal
            ? "flex absolute top-0 left-0 right-0 justify-center items-center bg-[#00000024] w-full h-screen"
            : "hidden"
        }
      >
        <div
          className={
            epargneInfoModal
              ? " w-3/12 h-[220px] flex flex-col rounded-md  bg-sky-900"
              : "hidden"
          }
        >
          {/* Header */}
          <div className="w-full flex flex-row justify-between p-2">
            <button className="bg-gray-200 w-8 h-8 flex opacity-0 rounded-full justify-center items-center ">
              <IoMdClose size={20} color="black" />
            </button>

            <div className="col">
              <p className="font-bold text-lg text-white">Commission Epargne</p>
              <p className="font-bold text-white text-center">({colName})</p>
            </div>

            <button
              onClick={() => setEpargneInfoModal(false)}
              className="bg-gray-200 w-8 h-8 flex hover:bg-gray-500 rounded-full justify-center items-center "
            >
              <IoMdClose size={20} color="black" />
            </button>
          </div>

          {/* Body */}
          {/* Commission du mois */}
          <span className="flex flex-col items-center">
            <p className="font-bold text-white">Commission de ce mois</p>
            <p className="text-green-400 font-bold text-lg">
              {loadingE ? (
                <img src={C_loading} className="w-5 h-5" />
              ) : (
                (totalCommissionE || 0).toLocaleString("fr-FR")
              )}{" "}
              FCFA
            </p>
          </span>
        </div>
      </div>

      {/* Carnet info Modal */}
      <div
        onClickCapture={() => setCarnetInfoModal(false)}
        className={
          carnetInfoModal
            ? "flex absolute top-0 left-0 right-0 justify-center items-center bg-[#00000024] w-full h-screen"
            : "hidden"
        }
      >
        <div
          className={
            carnetInfoModal
              ? " w-3/12 h-[220px] flex flex-col rounded-md  bg-sky-900"
              : "hidden"
          }
        >
          {/* Header */}
          <div className="w-full flex flex-row justify-between p-2">
            <button className="bg-gray-200 w-8 h-8 flex opacity-0 rounded-full justify-center items-center ">
              <IoMdClose size={20} color="black" />
            </button>

            <div className="flex flex-col">
              <p className="font-bold text-lg text-white">Commission Carnets</p>
              <p className="text-center text-white font-bold">({colName})</p>
            </div>

            <button
              onClick={() => setCarnetInfoModal(false)}
              className="bg-gray-200 w-8 h-8 flex hover:bg-gray-500 rounded-full justify-center items-center "
            >
              <IoMdClose size={20} color="black" />
            </button>
          </div>

          {/* Body */}
          <div className="mt-3">
            {/* Nombre de carnet vendue */}
            <span className="flex flex-col items-center">
              <p className="font-bold text-white">Nombre de vente</p>
              <p className="text-red-600 font-bold text-lg">
                {loadingC ? (
                  <img src={C_loading} className="w-5 h-5" />
                ) : (
                  carnetsData.totalCarnets
                )}
              </p>
            </span>

            {/* Commission du mois */}
            <span className="flex flex-col items-center">
              <p className="font-bold text-white">Commission de ce mois</p>
              <p className="text-green-400 font-bold text-lg">
                {loadingC ? (
                  <img src={C_loading} className="w-5 h-5" />
                ) : (
                  (totalCommissionC || 0).toLocaleString("fr-FR")
                )}{" "}
                FCFA
              </p>
            </span>
          </div>
        </div>
      </div>

      {/* Total info Modal */}
      <div
        onClickCapture={() => setTotalInfoModal(false)}
        className={
          totalInfoModal
            ? "flex absolute top-0 left-0 right-0 justify-center items-center bg-[#00000024] w-full h-screen"
            : "hidden"
        }
      >
        <div
          className={
            totalInfoModal
              ? " w-3/12 h-[220px] flex flex-col  rounded-md  bg-sky-900"
              : "hidden"
          }
        >
          {/* Header */}
          <div className="w-full flex flex-row justify-between p-2">
            <button className="bg-gray-200 w-8 h-8 flex opacity-0 rounded-full justify-center items-center ">
              <IoMdClose size={20} color="black" />
            </button>

            <div>
              <p className="font-bold text-lg text-white">
                Salaire globale du mois
              </p>
              <p className="font-bold text-center text-white">({colName})</p>
            </div>

            <button
              onClick={() => setTotalInfoModal(false)}
              className="bg-gray-200 w-8 h-8 flex hover:bg-gray-500 rounded-full justify-center items-center "
            >
              <IoMdClose size={20} color="black" />
            </button>
          </div>

          {/* Body */}
          <div className="mt-3">
            {/* Salaire total */}
            <span className="flex flex-col items-center">
              <p className="font-bold text-white">Salaire total du mois</p>

              <div className="flex flex-row items-center">
                {/* Amount */}
                <p className="text-green-500 mt-2 font-bold text-2xl mr-2">
                  {loadingT ? (
                    <div className="z-10 bg-bg-[#00000024] w-20 h-10 flex justify-center items-center">
                      <img src={C_loading} className="w-5 h-5" alt="Loading" />
                    </div>
                  ) : (
                    (() => {
                      // Vérifiez et convertissez totalCommission
                      let commission = parseInt(calculatedCommission);

                      if (isNaN(commission)) {
                        console.error(
                          "Erreur de conversion:",
                          calculatedCommission
                        );
                        return "0 FCFA";
                      }

                      // Formatez le montant
                      return `${commission.toLocaleString("fr-FR")} FCFA`;
                    })()
                  )}
                </p>

                {/* Copy */}
                <CopyToClipboard text={calculatedCommission}>
                  <button
                    onClick={() =>
                      toast.success(`${calculatedCommission} FCFA est Copier`)
                    }
                    title="Copier le montant"
                    className="bg-[#22c55e24] p-2 rounded-md"
                  >
                    <IoMdCopy className="text-green-500" size={20} />
                  </button>
                </CopyToClipboard>
              </div>
            </span>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default AgenceDetails;
