import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { TbCardsFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import { BsInfoLg } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";
import qs from "qs";
import Refresh from "../../assets/loading.svg";

function CarnetList() {
  moment.locale("fr");
  const [carnetList, setCarnetList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [zImage, setZimage] = useState("");
  const [zName, setZName] = useState("");
  const [zMise, setZMise] = useState("");
  const [zId, setZId] = useState(null);
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/carnet/search?query=${search}`,
    headers: {},
  };

  useEffect(() => {
    const fetchControleur = async () => {
      try {
        const response = await axios.request(config);
        setCarnetList(response.data.result);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchControleur();
  }, [search]);

  // Delete Carnet


  const deleteCarnet = async () => {
    let config1 = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${url}/admin/remove/carnet/${zId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config1)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload("/dashboard/carnet");
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        } else if (error.response && error.response.status === 404) {
          toast(error.response.data.message);
        }
        console.log(error);
      });
  };

  let data = qs.stringify({
    carnetName: zName,
    mise: zMise,
  });

  // Edite Carnet Info
  const editCarnet = async () => {
    let config2 = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${url}/admin/edit/carnet/${zId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config2)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            setLoading1(false);
            setShowModal1(!showModal1);
            setShowModal(!showModal);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading1(false);
        toast.error(error.response.data.message);
      });
  };

  const lists = useMemo(() => {
    return (
      <div className="flex flex-row flex-wrap max-h-[85vh] overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[90vh] mb-[30%] max-sm:mb-[30%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : carnetList?.length > 0 ? (
          carnetList?.map(
            ({ id, nom, carnet, mise, stock_actif, created_date }) => (
              <Link
                key={id}
                className="m-2"
                onClick={() => {
                  setShowModal(true);
                  setZId(id);
                  setZimage(carnet);
                  setZName(nom);
                  setZMise(mise);
                }}
              >
                <img
                  loading="eager"
                  src={carnet}
                  className="w-full h-[210px] max-sm:h-[auto] rounded-md"
                />
              </Link>
            )
          )
        ) : (
          <div className="flex w-full justify-center items-center h-[85vh]">
            <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">
                Aucun Carnet trouver !
              </p>

              {/* Add NEW Controleur */}
              <Link to="/dashboard/create/carnet">
                <button className="bg-[#ADADAD36] hover:text-slate-700 rounded-full items-center p-2 flex flex-row">
                  <TbCardsFilled size={20} color="white" />
                  <p className="text-white ml-2">Crée un nouveau carnet</p>
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }, [carnetList, zImage]);

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add NEW Controleur */}
          <Link to="/dashboard/create/carnet">
            <button
              title="Ajouter"
              className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
            >
              <TbCardsFilled size={20} color="white" />
              <p className="text-white ml-2 text-xs">Crée un carnet</p>
            </button>
          </Link>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche de carnet"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Carnet List */}
        {lists}
      </div>

      {/* Info Popup */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute flex flex-col w-[50%] h-full top-0 max-sm:w-full rounded-xl justify-between bg-gray-900 "
          }
        >
          <div className="mt-2 flex flex-row justify-between items-center px-3">
            {/* I */}
            <div className="p-1 rounded-full bg-gray-800">
              <BsInfoLg size={30} color="#fff" />
            </div>

            <p className="font-bold text-white text-lg text-center">{zName}</p>

            {/* Close */}
            <button
              onClick={() => setShowModal(false)}
              className="p-1 rounded-full bg-gray-800"
            >
              <MdClose size={30} color="#fff" />
            </button>
          </div>

          {/* Body */}
          <div className="flex flex-col justify-between absolute top-20">
            {/* Action button */}
            <div className="flex flex-row my-4">
              {/* Edit */}
              <button
                onClick={() => {
                  setShowModal1(true);
                  setShowModal2(false);
                }}
                className="bg-white p-2 rounded-xl flex flex-row items-center"
              >
                <FaRegEdit className="text-black" />
                <p>Modifier</p>
              </button>

              {/* Delete */}
              <button
                className="bg-[#F1000029] p-2 rounded-xl flex flex-row items-center ml-2"
                onClick={() => {
                  setShowModal2(true);
                  setShowModal1(false);
                }}
              >
                <RiDeleteBin5Line className="text-white" />
                <p className="text-white">Supprimé</p>
              </button>
            </div>
            {/* Image */}
            <div>
              <img className="w-full h-full rounded-xl" src={zImage} />
            </div>
          </div>
        </div>
      </div>

      {/* Edite Carnet Info */}
      <div
        className={
          !showModal1
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal1
              ? "hidden"
              : "absolute w-[340px] h-[380px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#00C95640]">
              <FaRegEdit size={30} color="green" />
            </div>

            {/* Close */}
            <button
              onClick={() => setShowModal1(false)}
              className="p-1 rounded-full absolute right-2 bg-gray-800"
            >
              <MdClose size={30} color="#fff" />
            </button>
          </div>

          {/* Title */}
          <div className="mb-[10px] mt-12">
            <p className="text-white font-bold text-xl text-center">
              Modifier ce carnet
            </p>
          </div>

          <div className="px-[10%] w-full  flex flex-col justify-between bottom-28">
            {/* Form */}
            <div>
              {/* Nom  */}
              <div className="bg-white text-base font-bold my-3 p-2 rounded-xl">
                <p className="text-lg font-bold text-blue-500">Nom</p>
                <input
                  autoFocus
                  className="border-none outline-none bg-transparent text-base font-medium w-full"
                  defaultValue={zName}
                  onChange={(e) => setZName(e.target.value)}
                />
              </div>

              {/* Mise  */}
              <div className="bg-white text-base font-bold p-2 rounded-xl">
                <p className="text-lg font-bold text-blue-500">Mise</p>
                <input
                  className="border-none outline-none bg-transparent text-base font-medium w-full"
                  defaultValue={zMise}
                  onChange={(e) => setZMise(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-center mt-5 px-5">
              {/* Save  */}
              <button
                onClick={() => {
                  editCarnet();
                  setLoading1(true);
                }}
                className="w-full h-[45px] flex bg-green-600 rounded-md items-center justify-center hover:bg-slate-600 mx-2"
              >
                {!loading1 ? (
                  <p className="text-white font-medium text-base">
                    Sauvegarder
                  </p>
                ) : (
                  <img
                    src={Refresh}
                    className="w-[20px] text-center h-[20px]"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Carnet Pop up */}
      <div
        className={
          !showModal2
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal2
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <RiDeleteBinLine size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Suppression définitif de ce carnet
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={deleteCarnet}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal2(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>

      {/* Toasty */}
      <ToastContainer />
    </>
  );
}

export default CarnetList;
