import React, { useState } from "react";
import ico from "../assets/icon.png";
import Ico2 from "../assets/g-vendor/g-vendor.png";
import Loading from "../assets/loading.svg";
import { FaEye } from "react-icons/fa";
import { FaEyeLowVision } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { IoLockOpenOutline } from "react-icons/io5";
import Config from "../config/Links";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import bbg from "../assets/bbg.jpg";
import Sbg from "../assets/g-performance/SSSbg.jpg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Loading_blue from "../assets/svg/loading_blue.json";

const url = Config.G_PERFORM_LINK;

function GPerfLogin() {
  const [showPass, setShowPasse] = useState(false);
  const [numero, setNumero] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [err1, setErr1] = useState(false);

  // Login Request

  let config = {
    method: "POST",
    url: `${url}/admin/login`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      code_telephone: "+228",
      numero_telephone: numero,
      mot_de_passe: pass,
    },
  };

  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/connected/to/gescapo`,
  };

  const login = async () => {
    await axios
      .request(config1)
      .then((response) => {
        sessionStorage.setItem("gestoken", response.data.data.access_token);
        sessionStorage.setItem("admin_type", 0);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("admin_tk", response.data.token);
          console.log(response.data);
          toast.success("Connexion réussie");
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1500);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Erreur de connexion !");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="w-full h-screen bg-[#000] flex flex-row">
        {/* Left */}
        <div
          style={{ backgroundImage: `url(${bbg})` }}
          className="bg-contain w-[78%] h-screen max-sm:hidden flex flex-row"
        ></div>

        {/* Auth */}
        <div
          style={{ backgroundImage: `url(${Sbg})` }}
          className="px-10 flex justify-center items-center"
        >
          <div className="flex flex-col items-center h-[60%] rounded-l-xl m-3 bg-[#ffffff20] rounded-xl shadow-md px-1 py-3 ">
            {/* Logo */}
            <div className="mb-[10%]">
              <img
                src={ico}
                className="w-[160px] h-[40px] mt-5 rounded-md"
                loading="lazy"
              />
            </div>

            {/* Forms */}
            <div className="max-sm:mt-5 w-full mb-20 h-[120px] justify-between px-[50px]">
              {/* Phone */}
              <div>
                <p className="text-gray-300 font-bold mb-2">Téléphone</p>
                <div className="bg-[#ffffff20] h-[55px] px-3 rounded-md flex flex-row border-[1px] border-gray-300 items-center">
                  <FiUser size={30} color="gray" />
                  <input
                    required
                    placeholder="Téléphone"
                    maxLength={8}
                    autoFocus
                    type="text"
                    className="border-none bg-transparent text-white outline-none ml-2 mr-2 p-3 w-full font-semibold"
                    value={numero}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^\d.]/g, "");
                      setNumero(filteredInput);
                    }}
                  />
                </div>

                {/* Err */}
                {err ? (
                  <div>
                    <p className="text-red-500">Veuillez remplire ce champ !</p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Password */}
              <div className="mt-5">
                <p className="text-gray-300 font-bold mb-2">Mot de passe</p>
                <div className="bg-[#ffffff20] h-[55px] justify-between border-[1px] border-gray-300 rounded-md px-1 flex flex-row items-center ">
                  <IoLockOpenOutline size={30} color="gray" className="ml-2" />
                  <input
                    required
                    placeholder="Mot de passe"
                    type={showPass ? "text" : "password"}
                    className="border-none bg-transparent text-white outline-none w-full ml-2 mr-2 p-3 font-semibold"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                  />

                  <button
                    className="p-1"
                    onClick={() => setShowPasse(!showPass)}
                  >
                    {showPass ? (
                      <FaEyeLowVision color="gray" size={30} />
                    ) : (
                      <FaEye color="gray" size={30} />
                    )}
                  </button>
                </div>

                {/* Err */}
                {err1 ? (
                  <div>
                    <p className="text-red-500">Mot de passe obligatoire !</p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Button */}
              <div className="mt-[20%] flex justify-center items-center">
                {loading ? (
                  <img src={Loading} className="w-8 h-8" />
                ) : (
                  <button
                    disabled={numero.length < 1 || pass.length < 1}
                    onClick={() => {
                      if (numero.length < 1) {
                        setLoading(false);
                        setErr(true);
                      } else if (pass.length < 1) {
                        setLoading(false);
                        setErr1(true);
                      } else if (numero.length > 1 && pass.length > 1) {
                        setErr(false);
                        setErr1(false);
                      }

                      login();
                      setLoading(!loading);
                    }}
                    type="submit"
                    className={
                      numero.length < 1 || pass.length < 1
                        ? "bg-blue-700 text-white text-lg w-10/12 p-3 rounded-xl opacity-30"
                        : "bg-blue-700 text-white text-lg w-10/12 p-3 hover:bg-blue-900 rounded-xl"
                    }
                  >
                    Se connecter
                  </button>
                )}
              </div>

              {/* Copy */}
              <div className="mt-[60px] flex justify-center items-center">
                <p className="flex text-gray-300 text-center">
                  Tous droits réservés © {new Date().getFullYear()}, Copyright
                  by GESCAPRO
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toastify */}
      <ToastContainer />
    </>
  );
}

function GVendorLogin() {
  const [showPass, setShowPasse] = useState(false);
  const [numero, setNumero] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [err1, setErr1] = useState(false);

  // Login Request

  let config = {
    method: "POST",
    url: `${url}/admin/login`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      code_telephone: "+228",
      numero_telephone: numero,
      mot_de_passe: pass,
    },
  };

  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/connected/to/gescapo`,
  };

  const login = async () => {
    await axios
      .request(config1)
      .then((response) => {
        sessionStorage.setItem("gestoken", response.data.data.access_token);
        sessionStorage.setItem("admin_type", 1);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("admin_tk", response.data.token);
          console.log(response.data);
          toast.success("Connexion réussie");
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1500);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Erreur de connexion !");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
        {/* Logo */}
        <div className="text-center mb-8">
          <img src={Ico2} alt="Logo" className="w-40 h-20 mx-auto mb-4" />

          <h2 className="text-2xl font-bold text-gray-800">Connexion Admin</h2>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
          className="space-y-6"
        >
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Téléphone
            </label>
            <div className="relative">
              <input
                id="phone"
                type="tel"
                required
                maxLength={8}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Entrez votre numéro"
                value={numero}
                onChange={(e) => setNumero(e.target.value.replace(/\D/g, ""))}
              />
              <FiUser
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
            </div>
            {err && (
              <p className="mt-1 text-sm text-red-600">
                Veuillez remplir ce champ
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Mot de passe
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPass ? "text" : "password"}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Entrez votre mot de passe"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPasse(!showPass)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 focus:outline-none"
              >
                {showPass ? <FaEyeLowVision size={20} /> : <FaEye size={20} />}
              </button>
            </div>
            {err1 && (
              <p className="mt-1 text-sm text-red-600">
                Mot de passe obligatoire
              </p>
            )}
          </div>

          {loading ? (
            <Player
              autoplay
              loop
              src={Loading_blue}
              style={{ height: "80px", width: "80px" }}
            />
          ) : (
            <div className="flex flex-col justify-center items-center">
              <button
                disabled={numero.length < 1 || pass.length < 1}
                onClick={() => {
                  if (numero.length < 1) {
                    setLoading(false);
                    setErr(true);
                  } else if (pass.length < 1) {
                    setLoading(false);
                    setErr1(true);
                  } else if (numero.length > 1 && pass.length > 1) {
                    setErr(false);
                    setErr1(false);
                  }

                  login();
                  setLoading(!loading);
                }}
                type="submit"
                className={
                  numero.length < 1 || pass.length < 1
                    ? "bg-blue-700 text-white text-lg w-10/12 p-3 rounded-xl opacity-30"
                    : "bg-blue-700 text-white text-lg w-10/12 p-3 hover:bg-blue-900 rounded-xl"
                }
              >
                Se connecter
              </button>
            </div>
          )}
        </form>

        <p className="mt-8 text-center text-sm text-gray-600">
          Tous droits réservés © {new Date().getFullYear()}, Copyright by
          GESCAPRO
        </p>
      </div>
      <ToastContainer />
    </div>
  );
}

export default { GPerfLogin, GVendorLogin };
