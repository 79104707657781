import React, { useState } from "react";
import Logo from "../assets/icon.png";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { LuMoon } from "react-icons/lu";
import { IoSunnyOutline } from "react-icons/io5";

// Theme Select
const theme = [
  { id: 0, name: "black", ico: <LuMoon size={20} color="white" /> },
  { id: 1, name: "light", ico: <IoSunnyOutline size={20} color="white" /> },
];

function Header() {
  const [showModal, setShowModal] = useState(false);
  const [initialAdmin, setInitialAdmin] = useState(0);
  const [themeId, setThemeId] = useState(0);

  return (
    <>
      <div className="w-full max-sm:w-full h-[55px] bg-[#0f0f0f] p-1 fixed justify-between px-5 max-sm:px-1 flex items-center top-0 left-0 right-0">
        <div className="w-[15%] flex justify-center">
          <img
            className="w-[140px] h-[35px] mt-5 max-sm:w-[80px] max-sm:h-[25px] rounded-md"
            src={Logo}
          />
        </div>

        <div className="max-sm:w-[150px] w-2/12 flex items-center max-sm:items-end justify-around max-sm:justify-end max-sm:px-3">
          {/* Notification */}
          {/* <Link to="/dashboard/notification" className="ml-2">
            <div className="absolute top-3 ml-3 w-[15px] h-[15px] rounded-full border-[0.5] bg-red-600 flex items-center justify-center border-white p-1 z-10">
              <p className="text-white text-xs">5</p>
            </div>
            <IoNotificationsOutline
              size={25}
              color="#fff"
              className="rotation-10"
            />
          </Link> */}

          {/* Switch to theme */}
          <div className="flex items-center justify-between mr-3 w-[95px] bg-[#ffffff14] rounded-full p-1">
            {theme.map((item, index) => {
              return (
                <button
                  className={
                    item.id === themeId
                      ? "bg-black p-2 rounded-full"
                      : "bg-[transparent] p-2 rounded-full"
                  }
                  key={index}
                  onClick={() => {
                    setThemeId(item.id);
                    alert("Fonction en cours de developpement !");
                  }}
                >
                  {item.ico}
                </button>
              );
            })}
          </div>

          {/* Switch Profil */}
          {/* <div
            title="Changer de compte"
            className="hover:bg-teal-950 w-10 cursor-pointer rounded-md p-1 items-center justify-center"
          >
            <button
              onClick={() => {
                alert(
                  "Vous n'êtes pas authoriser à cet rentré dans cette zone !!"
                );
                const switchToLgg = sessionStorage.setItem("admin_type", 1);
                initialAdmin === 0 && setInitialAdmin(switchToLgg);
                window.location.replace("/dashboard");
              }}
            >
              <AiOutlineUserSwitch size={25} color="white" />
            </button>
            <LuDot size={25} className="absolute top-1 ml-3 text-green-500" />
          </div> */}

          {/* Profile */}
          <Link
            to="/dashboard/profil"
            className="max-sm:ml-6 border-[2px] border-green-500 rounded-full max-sm-mr-4"
          >
            <FaUserCircle size={30} color="#fff" />
            <span class="absolute flex h-3 w-3 bottom-3 ml-5">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-85"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          </Link>

          {/* Logout */}
          <button
            className="flex p-2 rounded-xl hover:bg-[#ffffff34] bg-[#ffffff14] justify-center items-center mx-2"
            onClick={() => setShowModal(true)}
          >
            <CiLogout color="red" size={23} />
          </button>
        </div>
      </div>

      {/* LOgPop */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-8/12 absolute bg-black z-10 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <CiLogout size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Etes-vous sûr de vouloir vous déconnecter ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => {
                sessionStorage.removeItem("admin_tk");
                sessionStorage.removeItem("gestoken");

                setTimeout(() => {
                  window.location.replace("/");
                }, 1500);
              }}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
