import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Config from "../../../lgg_addOn/config/Links";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/svg/order.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdClose } from "react-icons/io";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FiPackage } from "react-icons/fi";
import { FaPencilAlt } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import { CiImageOn } from "react-icons/ci";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";

// Request menu
const requestMenu = [
  { id: 0, title: "Tous" },
  { id: 1, title: "Aujourd'hui" },
];

// Order Menu
const oredersMenu = [
  { id: 0, title: "Tous" },
  { id: 1, title: "Aujourd'hui" },
];

export default function ProductList() {
  moment.locale("fr");
  const [nameSearch, setNameSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loadings, setLoadings] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [clientsOrders, setClientsOrders] = useState([]);
  const [clientsRequests, setClientsRequests] = useState([]);
  const [select, setSelect] = useState(0);
  const [select1, setSelect1] = useState(0);
  const [todayOrder, setTodayOrder] = useState([]);
  const [todayRequest, setTodayRequest] = useState([]);
  const [dayOrderLength, setDayOrderLength] = useState("");
  const [dayRequestLength, setDayRequestLength] = useState("");
  const [colName, setColName] = useState("");
  const [colName1, setColName1] = useState("");
  const [notFound1, setNotFound1] = useState(false);
  const [notFound2, setNotFound2] = useState(false);

  // Create Product Modal
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  // Create Product State
  const [productImage, setProductImage] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCommission, setProductCommission] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  // Create New Product
  const handleCreateProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = new FormData();
    data.append("m_product_img", productImage);
    data.append("nom", productName);
    data.append("prix", productPrice);
    data.append("commission", productCommission);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/new/magasin/product`,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast(response.data.message);
        setProductImage("");
        setProductName("");
        setProductPrice("");
        setProductCommission("");
        fetchAllProduct();
        setModal(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Fetch All Product
  const fetchAllProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/magasin/preview/products?query=${nameSearch}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound(false);
        setLoadings(false);
        setProducts(response.data.products);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound(true);
        }
        setLoadings(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllProduct();
  }, [nameSearch]);

  // Get All clients Orders
  useEffect(() => {
    fetchAllOrders();
  }, [colName]);

  const fetchAllOrders = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/admin/get/all/clients/orders?query=${colName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound1(false);
        setClientsOrders(response.data.requests);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound1(true);
        }
        console.log(error);
      });
  };

  // Get All clients Request
  useEffect(() => {
    fetchAllRequests();
  }, [colName1]);

  const fetchAllRequests = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/admin/get/all/clients/requests?query=${colName1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound2(false);
        setClientsRequests(response.data.orders);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound2(true);
        }
        console.log(error);
      });
  };

  // Get To day Orders
  useEffect(() => {
    fetchAllOrders();
  }, []);

  const getToDayOrders = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/to/day/order`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setTodayOrder(response.data.orders);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Length Liste
  useEffect(() => {
    const getToDayOrders = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/to/day/order`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setDayOrderLength(response.data.orders_length);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getToDayOrders();
  });

  // Get To Day Requests
  useEffect(() => {
    getTodayRequest();
  }, []);

  const getTodayRequest = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/to/day/request`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setTodayRequest(response.data.request);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Lenght lIste
  useEffect(() => {
    const getTodayRequest = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/to/day/request`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setDayRequestLength(response.data.request_length);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTodayRequest();
  });

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add New Product Button */}
          <div className="flex  items-center">
            {/* Add */}
            <button
              onClick={() => setModal(true)}
              className="bg-[#ffffff24] p-2 flex items-center rounded-md"
            >
              <MdOutlineAddShoppingCart size={25} color="white" />
              <p className="text-white ml-2">Ajouter un produit</p>
            </button>

            {/* Orders List */}
            <button
              onClick={() => {
                setModal1(true);
                fetchAllOrders();
                getToDayOrders();
              }}
              className="bg-[#ffffff24] p-2 flex mx-3 items-center rounded-md"
            >
              <FiPackage size={25} color="white" />
              <p className="text-white ml-2">Commandes des clients</p>
              <p
                className={
                  dayOrderLength == 0
                    ? "hidden"
                    : "bg-red-500 text-xs text-white text-center p-1 absolute rounded-full mb-[50px] mr-2 w-5 h-5"
                }
              >
                {dayOrderLength}
              </p>
            </button>

            {/* Request Liste */}
            <button
              onClick={() => {
                setModal2(true);
                fetchAllRequests();
                getTodayRequest();
              }}
              className="bg-[#ffffff24] p-2 flex items-center rounded-md"
            >
              <FaPencilAlt size={25} color="white" />
              <p className="text-white ml-2">Demandes des clients</p>
              <p
                className={
                  dayRequestLength == 0
                    ? "hidden"
                    : "bg-red-500 text-xs text-white text-center p-1 absolute rounded-full mb-[50px] mr-2 w-5 h-5"
                }
              >
                {dayRequestLength}
              </p>
            </button>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recherche des produits.."
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Body */}
        {loadings ? (
          <div className="flex items-center justify-center h-[300px]">
            <img src={Load} alt="Chargement" className="w-10 h-10" />
          </div>
        ) : notFound ? (
          <div className="flex items-center flex-col justify-center h-[300px]">
            <img src={Nothing} alt="Chargement" className="w-32 h-32" />
            <p className="text-white text-center">Aucun produit trouvé.</p>
          </div>
        ) : (
          <div className="w-full h-[auto] overflow-auto scrollbar-hide grid grid-cols-9 max-sm:grid max-sm:grid-cols-2">
            {[...products].map((product) => {
              return (
                <button
                  key={product.id}
                  className="m-1 flex flex-col items-start"
                >
                  <img
                    src={product.m_product_img}
                    alt={product.nom}
                    className="w-[150px] h-[150px] rounded-md"
                  />

                  {/* Name */}
                  <h2 className="text-white font-medium text-center">
                    {product.nom}
                  </h2>

                  {/* Commission */}
                  <h2 className="text-green-500 font-medium">
                    Commission :{" "}
                    {(
                      parseInt(product.commission).toFixed(0) * 1
                    ).toLocaleString("fr-FR") +
                      " " +
                      "F"}
                  </h2>

                  {/* Seller Price */}
                  <h2 className="text-red-500 font-medium">
                    Prix :{" "}
                    {(parseInt(product.prix).toFixed(0) * 1).toLocaleString(
                      "fr-FR"
                    ) +
                      " " +
                      "F"}
                  </h2>

                  {/* Date d'ajout */}
                  <h2 className="text-gray-400 font-medium">
                    Crée : {moment(product.created_at).calendar()}
                  </h2>
                </button>
              );
            })}
          </div>
        )}
      </div>

      {/* Create New Product */}
      <div
        className={
          modal
            ? "w-[84%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] absolute top-20 px-5 w-[35%] h-[90vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Créer un produit
            </h2>
            {/* Close Button */}
            <button
              onClick={() => setModal(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div>
            {/* Image */}
            <div className="mt-5">
              {imagePreview === "" ? (
                <label
                  htmlFor="img"
                  className="cursor-pointer border-[1px] h-[200px] flex flex-col items-center justify-center border-white m-2 rounded-xl border-dashed"
                >
                  <div className="flex flex-row items-center">
                    <input
                      type="file"
                      accept="
                      image/*"
                      id="img"
                      name="image_url"
                      className="hidden w-0 h-0"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setProductImage(file);
                        setImagePreview(URL.createObjectURL(file));
                      }}
                    />

                    <LiaCartPlusSolid size={60} color="#fff" />
                    <p className="text-white mx-2">+</p>
                    <CiImageOn size={60} color="#fff" />
                  </div>
                  <p className="text-white my-6">Ajouter l'image du produit</p>
                </label>
              ) : (
                <img
                  src={imagePreview}
                  className="w-[auto] rounded-xl h-[auto]"
                  alt="Aperçu de l'image"
                />
              )}
            </div>

            {/* Product Name */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center mt-12 p-2 rounded-lg mb-4">
              <LiaCartPlusSolid size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Nom du produit"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            {/*  Price */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <input
                required
                type="text"
                placeholder="Prix de vente"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={productPrice}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setProductPrice(filteredInput);
                }}
              />
              <p className="text-white">FCFA</p>
            </div>

            {/*  Commission */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <input
                required
                type="text"
                placeholder="Commission"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={productCommission}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setProductCommission(filteredInput);
                }}
              />
              <p className="text-white">FCFA</p>
            </div>

            {/* Button For Submite */}
            <div>
              <button
                disabled={
                  productImage.length === 0 ||
                  productName.length === 0 ||
                  productPrice.length === 0 ||
                  productCommission.length === 0
                }
                onClick={handleCreateProduct}
                className={
                  productImage.length === 0 ||
                  productName.length === 0 ||
                  productPrice.length === 0 ||
                  productCommission.length === 0
                    ? "w-full max-sm:w-[300px] h-12 bg-[#007bff] text-white font-medium rounded-md px-4 py-2 opacity-40"
                    : "w-full max-sm:w-[300px] h-12 bg-[#007bff] text-white font-medium rounded-md px-4 py-2"
                }
              >
                Créer le produit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Get All Clients Orders Listes */}
      <div
        className={
          modal1
            ? "w-[84%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] absolute top-20 px-5 w-[40%] h-[90vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Liste des commandes clients
            </h2>
            {/* Close Button */}
            <button
              onClick={() => setModal1(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between">
            <div className="w-4/12 p-2 rounded-full flex felx-row items-center bg-slate-700">
              {[...oredersMenu].map((menu, index) => (
                <button
                  onClick={() => setSelect(menu.id)}
                  key={index}
                  className={
                    menu.id === select
                      ? "bg-slate-500 p-2 rounded-full w-full text-white"
                      : "p-2 rounded-full w-full text-white"
                  }
                >
                  {menu.title}
                </button>
              ))}
            </div>

            {/* Searchbar */}
            <div
              title="Recherche"
              className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
            >
              <CiSearch size={25} color="white" />
              <input
                type="search"
                placeholder="Filtrage"
                className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
                value={colName}
                onChange={(e) => setColName(e.target.value)}
              />
            </div>
          </div>

          {/* Body */}
          {notFound1 ? (
            <div className="w-full h-full">
              <h2 className="text-white text-center mt-10">
                Aucun rusultat trouvé !
              </h2>
            </div>
          ) : (
            select === 0 && (
              <div className="flex flex-col overflow-auto h-[88%] scrollbar-hide">
                {[...clientsOrders].map((order) => {
                  return (
                    <div
                      key={order.id}
                      className="bg-[#ffffff14] flex items-center my-2 rounded-xl p-3"
                    >
                      {/* Left */}
                      <img src={order.product_image} className="w-10 h-10" />

                      {/* Right */}
                      <div className="flex justify-between w-full">
                        {/* #1 */}
                        <div className="ml-3">
                          <p className="text-gray-400">
                            Commande : #{order.id}
                          </p>

                          <h2 className="text-white text-base font-medium">
                            {order.product_name}
                          </h2>

                          <p className="text-green-600 font-bold">
                            Prix :{" "}
                            {(
                              parseInt(order.product_price).toFixed(0) * 1
                            ).toLocaleString("fr-FR")}{" "}
                            FCFA
                          </p>

                          <p className="text-white"> Col : {order.col_name}</p>
                        </div>

                        {/* #2 */}
                        <div className="ml-3">
                          <p className="text-red-600">
                            {" "}
                            Client : {order.client_name}
                          </p>

                          <p className="text-white">
                            {" "}
                            Telephone : +228 {order.client_number}
                          </p>

                          <p className="text-white">
                            {" "}
                            Date : {moment(order.created_date).calendar()}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          )}

          {select === 1 && (
            <div className="flex flex-col overflow-auto h-[88%] scrollbar-hide">
              {[...todayOrder].map((order) => {
                return (
                  <div
                    key={order.id}
                    className="bg-[#ffffff14] flex items-center my-2 rounded-xl p-3"
                  >
                    {/* Left */}
                    <img src={order.product_image} className="w-10 h-10" />

                    {/* Right */}
                    <div className="flex justify-between w-full">
                      {/* #1 */}
                      <div className="ml-3">
                        <p className="text-gray-400">Commande : #{order.id}</p>

                        <h2 className="text-white text-base font-medium">
                          {order.product_name}
                        </h2>

                        <p className="text-green-600 font-bold">
                          Prix :{" "}
                          {(
                            parseInt(order.product_price).toFixed(0) * 1
                          ).toLocaleString("fr-FR")}{" "}
                          FCFA
                        </p>

                        <p className="text-white"> Col : {order.col_name}</p>
                      </div>

                      {/* #2 */}
                      <div className="ml-3">
                        <p className="text-red-600">
                          {" "}
                          Client : {order.client_name}
                        </p>

                        <p className="text-white">
                          {" "}
                          Telephone : +228 {order.client_number}
                        </p>

                        <p className="text-white">
                          {" "}
                          Date : {moment(order.created_date).calendar()}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Get All client requests Listes */}
      <div
        className={
          modal2
            ? "w-[84%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] absolute top-20 px-5 w-[40%] h-[90vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Listes des demandes clients
            </h2>
            {/* Close Button */}
            <button
              onClick={() => setModal2(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between">
            <div className="w-4/12 p-2 rounded-full flex felx-row items-center bg-slate-700">
              {[...requestMenu].map((menu, index) => (
                <button
                  onClick={() => setSelect1(menu.id)}
                  key={index}
                  className={
                    menu.id === select1
                      ? "bg-slate-500 p-2 rounded-full w-full text-white"
                      : "p-2 rounded-full w-full text-white"
                  }
                >
                  {menu.title}
                </button>
              ))}
            </div>

            {/* Searchbar */}
            <div
              title="Recherche"
              className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
            >
              <CiSearch size={25} color="white" />
              <input
                type="search"
                placeholder="Filtrage"
                className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
                value={colName1}
                onChange={(e) => setColName1(e.target.value)}
              />
            </div>
          </div>

          {/* Body */}
          {notFound2 ? (
            <div className="w-full h-full">
              <h2 className="text-white mt-10 text-center">
                Aucun resultat trouvé{" "}
              </h2>
            </div>
          ) : (
            select1 === 0 && (
              <div className="flex flex-col overflow-auto h-[88%] scrollbar-hide">
                {[...clientsRequests].map((request) => {
                  return (
                    <div
                      key={request.id}
                      className="bg-[#ffffff14] flex items-center my-2 rounded-xl p-3"
                    >
                      {/* Right */}
                      <div className="flex justify-between w-full">
                        {/* #1 */}
                        <div className="ml-3">
                          <p className="text-gray-400">
                            Commande : #{request.id}
                          </p>

                          <h2 className="text-white text-base font-medium">
                            {request.product_name}
                          </h2>

                          <p className="text-green-600 font-bold">
                            Prix du client :{" "}
                            {(
                              parseInt(request.client_price).toFixed(0) * 1
                            ).toLocaleString("fr-FR")}{" "}
                            FCFA
                          </p>

                          <p className="text-white">
                            {" "}
                            Col : {request.vendor_name}
                          </p>
                        </div>

                        {/* #2 */}
                        <div className="ml-3">
                          <p className="text-red-600">
                            {" "}
                            Client : {request.client_name}
                          </p>

                          <p className="text-white">
                            {" "}
                            Telephone : +228 {request.client_phone}
                          </p>

                          <p className="text-red-600 font-bold">
                            Prix de l'Admin : {request.admin_price} FCFA
                          </p>

                          <p className="text-white">
                            {" "}
                            Date : {moment(request.created_at).calendar()}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          )}

          {select1 === 1 && (
            <div className="flex flex-col overflow-auto h-[88%] scrollbar-hide">
              {[...todayRequest].map((request) => {
                return (
                  <div
                    key={request.id}
                    className="bg-[#ffffff14] flex items-center my-2 rounded-xl p-3"
                  >
                    {/* Right */}
                    <div className="flex justify-between w-full">
                      {/* #1 */}
                      <div className="ml-3">
                        <p className="text-gray-400">
                          Commande : #{request.id}
                        </p>

                        <h2 className="text-white text-base font-medium">
                          {request.product_name}
                        </h2>

                        <p className="text-green-600 font-bold">
                          Prix du client :{" "}
                          {(
                            parseInt(request.client_price).toFixed(0) * 1
                          ).toLocaleString("fr-FR")}{" "}
                          FCFA
                        </p>

                        <p className="text-white">
                          {" "}
                          Col : {request.vendor_name}
                        </p>
                      </div>

                      {/* #2 */}
                      <div className="ml-3">
                        <p className="text-red-600">
                          {" "}
                          Client : {request.client_name}
                        </p>

                        <p className="text-white">
                          {" "}
                          Telephone : +228 {request.client_phone}
                        </p>

                        <p className="text-red-600 font-bold">
                          Prix de l'Admin : {request.admin_price} FCFA
                        </p>

                        <p className="text-white">
                          {" "}
                          Date : {moment(request.created_at).calendar()}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </>
  );
}
