import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import Config from "../../config/Links";
import { Link } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuUsers2 } from "react-icons/lu";

// Coll Rank menu

const ordersMenu = [
  { id: 0, title: "Carnet vendu" },
  { id: 1, title: "Déversement" },
];

export default function AgenceListe() {
  const [select, setSelect] = useState(0);
  const [agenceData, setAgenceData] = useState([]);
  const [colData, setColData] = useState([]);

  // Fetch data Agence && Collecteurs
  useEffect(() => {
    const fetchData = async () => {
      const gesToken = sessionStorage.getItem("gestoken");
      const headers = {
        Authorization: `Bearer ${gesToken}`,
      };

      try {
        const [agenceResponse, collecteursResponse] = await Promise.all([
          // Agence
          axios.post(
            `${Config.GESCAPO_LINK}/api/auth/admin/agence/list?all=true`,
            {},
            { headers }
          ),

          // Collecteurs
          axios.post(
            `${Config.GESCAPO_LINK}/api/auth/admin/agent_collecteur/list?all=true`,
            {},
            { headers }
          ),
        ]);

        setAgenceData(agenceResponse.data.data);
        setColData(collecteursResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getCollecteurCount = (agenceId) => {
    return colData.filter((cc) => cc.id_agence === agenceId).length;
  };

  const sortedAgenceData = useMemo(() => {
    return [...agenceData].sort((a, b) => {
      const countA = getCollecteurCount(a.id);
      const countB = getCollecteurCount(b.id);
      return countB - countA;
    });
  }, [agenceData, colData]);

  // Best Coll By Aell Carnet

  // Best Coll By Déversement


  return (
    <>
      <div className="flex flex-row items-start w-full justify-between px-5">
        {/* Agence */}
        <div className="w-5.5/12">
          {/* Title */}
          <span className="flex flex-row my-2">
            <FaRegBuilding size={25} color="#fff" />
            <p className="text-white font-bold text-2xl ml-2">
              Liste des Agences ({agenceData.length})
            </p>
          </span>

          {/* Body */}
          <div className="overflow-auto grid grid-cols-3 h-auto bg-[#ffffff14] rounded-md my-3 w-full scrollbar-hide">
            {sortedAgenceData.map((agence) => (
              <Link
                key={agence.id}
                to={`/dashboard/colecteur/agence/info/${agence.id}`}
              >
                <div className="bg-gray-800 p-3 m-2 rounded-xl shadow-md hover:bg-slate-900">
                  <div className="flex items-center">
                    <BsBuildings size={20} color="white" />
                    <h3 className="text-white ml-2">{agence.nom_agence}</h3>
                  </div>

                  <div className="flex items-center mt-2">
                    <HiOutlineLocationMarker size={20} color="white" />
                    <p className="text-green-500 ml-2">
                      {agence.quartier.libelle}
                    </p>
                  </div>

                  <div className="flex items-center mt-2">
                    <HiOutlineUsers size={20} color="white" />
                    <p className="text-yellow-500 ml-2">
                      {getCollecteurCount(agence.id)} Collecteurs
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Col Ranks */}
        <div className="w-5/12 ml-4">
          {/* Title */}
          <span className="flex flex-row items-center mb-4">
            <LuUsers2 size={28} color="#fff" />
            <h2 className="text-white font-bold text-2xl ml-3">
              Meilleurs Collecteurs (Mois)
            </h2>
          </span>

          {/* Body */}
          <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            {/* Navigation */}
            <div className="bg-gray-700 p-4">
              <div className="flex rounded-full bg-gray-600 p-1">
                {ordersMenu.map((menu) => (
                  <button
                    key={menu.id}
                    onClick={() => setSelect(menu.id)}
                    className={`flex-1 py-2 px-4 rounded-full transition-colors duration-200 ${
                      menu.id === select
                        ? "bg-blue-500 text-white"
                        : "text-gray-300 hover:bg-gray-500"
                    }`}
                  >
                    {menu.title}
                  </button>
                ))}
              </div>
            </div>

            {/* Content */}
            <div className="p-6">
              {select === 0 && (
                <div className="text-white">
                  {/* Add content for "Carnet vendu" here */}
                  Contenu pour Carnet vendu
                </div>
              )}
              {select === 1 && (
                <div className="text-white">
                  {/* Add content for "Déversement" here */}
                  Contenu pour Déversement
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
