import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../assets/rff.svg";
import { TbUsers } from "react-icons/tb";
import Config from "../../../config/Links";
import { RiUserSmileLine } from "react-icons/ri";
import { IoCartOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import moment from "moment";
import "moment/locale/fr";

// Icons
import { LuUsers2 } from "react-icons/lu";
import { PiCardsThreeThin } from "react-icons/pi";
import { GiShoppingCart } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrTrophy } from "react-icons/gr";

const state = [
  {
    id: 0,
    name: "Total Vendeurs",
    icon: <LuUsers2 size={40} color="#a832a6" />,
  },
  {
    id: 1,
    name: "Total Clients",
    icon: <LuUsers2 size={40} color="#a832a6" />,
  },
  {
    id: 2,
    name: "Nbre total de carnets vendu",
    icon: <PiCardsThreeThin size={45} color="#22C55E" />,
  },
  {
    id: 3,
    name: "Total de vente",
    icon: <GiShoppingCart size={40} color="#EF4444" />,
  },
  {
    id: 4,
    name: "Total de déversement",
    icon: <GiMoneyStack size={40} color="#3b82f6" />,
  },
  {
    id: 5,
    name: "Collecte en cours",
    icon: <GiTakeMyMoney size={40} color="#eab308" />,
  },
];

function NumberCol() {
  moment.locale("fr");

  // States
  const [col, setCol] = useState([]);

  // Best Col
  const [bCol, setBCol] = useState([]);
  const [bProduct, setProduct] = useState([]);

  // Bottom
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  //  Classement //
  // Cols
  useEffect(() => {
    const fetchBestCol = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/best/20/coll`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading(false);
          setBCol(response.data.collecteurs);
          console.log(response.data.collecteurs);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchBestCol();
  }, []);

  // Products
  useEffect(() => {
    const fetchBestCol = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/best/20/product`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading1(false);
          setLoading2(false);
          setProduct(response.data.carnets);
          console.log(response.data.carnets);
        })
        .catch((error) => {
          setLoading2(false);
          setLoading1(false);
          console.log(error);
        });
    };
    fetchBestCol();
  }, []);

  // Fetch
  const fetchVendors = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/lgg/col/list?query=`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCol(response.data.collecteurs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchVendors();
    const intervalId = setInterval(fetchVendors, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="h-[100vh] overflow-auto scrollbar-hide">
      {/* Statistique*/}
      <div className="mt-[65px] max-sm:pb-[5%] justify-start overflow-auto scrollbar-hide min-h-[300px] flex flex-wrap">
        {state.map(({ id, name, icon }) => (
          <div
            key={id}
            className="w-[200px] h-[250px] hover:w-[210px] hover:h-[265px] max-sm:w-full max-sm:h-[120px] flex flex-col justify-between p-3 m-2 max-sm:p-1  rounded-xl bg-[#ADADAD36]"
          >
            {/* Header */}
            <div className="flex flex-col items-center">
              <div
                className={
                  id === 0
                    ? "bg-[#a832a624] m-2 p-2 rounded-full items-center justify-center"
                    : id === 1
                    ? "bg-[#22C55E24] m-2 p-2 rounded-full items-center justify-center"
                    : id === 2
                    ? "bg-[#EF444424] m-2 p-2 rounded-full items-center justify-center"
                    : id === 3
                    ? "bg-[#3b82f624] m-2 p-2 rounded-full items-center justify-center"
                    : id === 4 &&
                      "bg-[#eab30824] m-2 p-2 rounded-full items-center justify-center"
                }
              >
                {icon}
              </div>
              <p className="text-white ml-3 font-medium text-base text-center">
                {name}
              </p>
            </div>

            {/* Number */}
            <p
              className={
                id === 0
                  ? "text-[#a832a6] text-xl text-center font-bold m-3"
                  : id === 1
                  ? "text-green-500 text-xl text-center font-bold m-3"
                  : id === 2
                  ? "text-red-500 text-xl text-center font-bold m-3"
                  : id === 3
                  ? "text-blue-500 text-xl text-center font-bold m-3"
                  : id === 4 &&
                    "text-yellow-500 text-center text-xl font-bold m-3"
              }
            >
              {id === 0
                ? <p>{parseFloat(col.length)}</p> || "--"
                : id === 1
                ? (
                    <p>
                      {parseFloat(
                        col.reduce((acc, cc) => acc + cc.nbre_carnet_vendue, 0)
                      )}
                    </p>
                  ) || "--"
                : id === 2
                ? (
                    <p>
                      {(
                        parseFloat(
                          col.reduce(
                            (acc, cc) => acc + cc.somme_total_collecter,
                            0
                          )
                        ).toFixed(0) * 1
                      ).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                  ) || "--"
                : id === 3
                ? (
                    <p>
                      {(
                        parseFloat(
                          col.reduce((acc, cc) => acc + cc.total_deversement, 0)
                        ).toFixed(0) * 1
                      ).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                  ) || "--"
                : (id === 4 && (
                    <p>
                      {" "}
                      {(
                        parseFloat(
                          col.reduce((acc, cc) => acc + cc.collect_en_cours, 0)
                        ).toFixed(0) * 1
                      ).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                  )) ||
                  "--"}
            </p>
          </div>
        ))}
      </div>

      {/* Classement */}
      {/* Listes Des Classements */}
      <div className="w-full flex p-2 max-sm:p-0 flex-wrap max-sm:flex-col items-center justify-between">
        {/* Classement meilleur collecteur  */}
        <div className="h-[80vh] mx-3 max-sm:mx-0 mb-3 w-[30%] rounded-xl max-sm:w-full overflow-auto scrollbar-hide flex flex-col">
          <div className="flex flex-row items-center p-2">
            <GrTrophy color="white" size={28} />
            <p className="text-white font-bold text-xl ml-2">
              Meilleur Vendeur Top 20
            </p>
          </div>

          {/* Body */}
          <div className="overflow-auto scrollbar-hide">
            {loading ? (
              <div className="flex justify-center h-full items-center">
                <img className="w-12 h-12" src={Loading} />
              </div>
            ) : bCol.length > 0 ? (
              bCol.map((col, index) => (
                <div
                  key={index}
                  className="flex flex-row w-4/5 my-2 items-center p-2 bg-[#ffffff24] hover:bg-slate-700 rounded-xl cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <RiUserSmileLine size={50} color="white" />

                    <div className="ml-2">
                      <p className="text-white">Rang : {col.rank}</p>
                      <p className="text-white">{col.nom_utilisateur}</p>
                      <p className="text-[#25ff03]">{col.nom_agence}</p>
                      <p className="text-red-500">
                        {" "}
                        Collectes G :{" "}
                        {(
                          parseFloat(col.somme_total_collecter).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "F"}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center">
                {/* <img
                  className="w-[50px] h-[50px]"
                  src={require("../../../assets/money.png")}
                /> */}
                <TbUsers size={100} color="white" />
                <p className="text-white">Aucun vendeur trouvé !</p>
              </div>
            )}
          </div>
        </div>

        {/* Classement meilleur produit top 20  */}
        <div className="h-[80vh] mx-3 max-sm:mx-0 mb-3 w-[30%] rounded-xl max-sm:w-full overflow-auto scrollbar-hide flex flex-col ">
          {/* Header */}
          <div className="flex flex-row items-center my-2">
            <GrTrophy color="white" size={28} />
            <p className="text-white font-bold text-xl ml-2">
              Meilleur produit top 20
            </p>
          </div>

          {/* Body */}
          <div className="overflow-auto scrollbar-hide">
            {loading1 ? (
              <div className="flex justify-center h-full items-center">
                <img className="w-12 h-12" src={Loading} />
              </div>
            ) : bProduct.length > 0 ? (
              bProduct.map((product, index) => (
                <div
                  key={index}
                  className="flex flex-row w-4/5 my-2 items-center p-2 bg-[#ffffff24] hover:bg-slate-700 rounded-xl cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <IoCartOutline size={50} color="white" />

                    <div className="ml-2">
                      <p className="text-white">{product.nom_produit}</p>
                      <p className="text-white">
                        {" "}
                        Prix achat :{" "}
                        {(
                          parseFloat(product.prix_achat).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "F"}
                      </p>
                      <p className="text-[#25ff03]">
                        {" "}
                        Nbre de vente : {product.sell_counter}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center">
                <IoCartOutline size={100} color="white" />
                <p className="text-white">Aucun produit trouvé !</p>
              </div>
            )}
          </div>
        </div>

        {/* Classement meilleur validité  */}
        <div className="h-[80vh] mx-3 max-sm:mx-0 mb-3 w-[30%] rounded-xl max-sm:w-full overflow-auto scrollbar-hide flex flex-col ">
          {/* Header */}
          <div className="flex flex-row items-center my-2">
            <GrTrophy color="white" size={28} />
            <p className="text-white font-bold text-xl ml-2">
              Meilleur validité Top 20
            </p>
          </div>

          {/* Body */}
          <div className="overflow-auto scrollbar-hide">
            {loading2 ? (
              <div className="flex justify-center h-full items-center">
                <img className="w-12 h-12" src={Loading} />
              </div>
            ) : bProduct.length > 0 ? (
              bProduct.map((product, index) => (
                <div
                  key={index}
                  className="flex flex-row w-4/5 my-2 items-center p-2 bg-[#ffffff24] hover:bg-slate-700 rounded-xl cursor-pointer"
                >
                  <div className="w-full flex flex-row justify-between">
                    <IoCartOutline size={50} color="white" />

                    <div className="ml-2">
                      <p className="text-white">{product.nom_produit}</p>
                      <p className="text-white">
                        {" "}
                        Prix vente :{" "}
                        {(
                          parseFloat(product.prix_vente).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "F"}
                      </p>
                      <p className="text-white">
                        {" "}
                        Marge :{" "}
                        {(
                          parseFloat(product.marge).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "F"}
                      </p>
                      <p className="text-[#25ff03]">
                        {" "}
                        Nbre de vente : {product.sell_counter}
                      </p>
                    </div>

                    {/* Left */}
                    <div className="flex ml-8 w-10 h-10 items-center justify-center p-1 rounded-full bg-slate-400">
                      <p>V {product.validity_expire}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center">
                <IoCartOutline size={100} color="white" />
                <p className="text-white">Aucun produit trouvé !</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumberCol;
