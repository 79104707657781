const Links = {
  GESCAPO_LINK: "https://api2-tggrossiste.gescapro.net",
  G_PERFORM_LINK: "https://gperformance.gwsboutique.com",

  // DSH
  // GESCAPO_LINK: "https://dshadmin.gescapro.net",
  // G_PERFORM_LINK: "https://dshgperformance.gwsboutique.com",
};

export default Links;
