import React from "react";
import StockGListe from "../../components/stock_general/StockGListe";

function Stock_general() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <StockGListe />
    </div>
  );
}

export default Stock_general;
